@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body {
  font-family: "Inter";
  background: #f4f3f3 !important;
}
[data-theme='dark'] body {
  background: #434343 !important; /* Text color in dark mode */
}

option {
  color: black;
}

.apexcharts-menu-item {
  color: #E56B20;
}