.rich-text h1 {
    font-size: 1.75rem;
    color: #000;
    font-weight: 700;
}

.rich-text h2 {
    font-size: 1.5rem;
    color: #000;
    font-weight: 700;
}

.rich-text h3 {
    font-size: 1.25rem;
    color: #000;
    font-weight: 700;
}

.rich-text p {
    font-size: 1rem;
    color: #4a5568;
    margin-bottom: 1rem;
}

.rich-text li {
    font-size: .75rem;
    color: #4a5568;
    margin-bottom: 0.5rem;
}

.rich-text a {
    color: #3182ce !important;
    text-decoration: underline;
}

.rich-text a:hover {
    color: #2b6cb0; 
}

.rich-text p a {
    color: #3182ce; 
}


.rich-text img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1rem 0;
}

.rich-text table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
}

.rich-text th, .rich-text td {
    border: 1px solid #e2e8f0;
    padding: 0.5rem;
}

.rich-text th {
    background-color: #f7fafc;
}
.rich-text {
    color: #000;
}

.rich-text a {
    color: #3182ce;
    text-decoration: none;
    font-style: italic;
}

.rich-text a:hover {
    color: #2b6cb0;
}
[data-theme='dark'] .rich-text {
    color: #fff; 
}
[data-theme='dark'] .rich-text a {
    color: #4fd1c5; 
}
[data-theme='dark'] .rich-text a:hover {
    color: #38b2ac;
}
[data-theme='dark'] .rich-text p {
    color: #fff; 
}

/*long description colors*/
.ql-toolbar.ql-snow, .ql-editor.ql-blank , .ql-container.ql-snow{
    border-color: inherit;
}
.ql-container.ql-snow{
    border-radius: 0 0 10px 10px;
}
.ql-toolbar.ql-snow{
    border-radius: 10px 10px 0 0;
}
.ql-editor.ql-blank::before{
    color: #c7c7c7;
    font-size:1rem;
}
[data-theme='dark'] .ql-snow .ql-picker.ql-header .ql-picker-label::before, .ql-snow .ql-picker.ql-header .ql-picker-item::before{
    color:#fff;
}
.ql-editor::before {
    color: white;
    opacity: 0.6;
}
[data-theme='dark'] .ql-toolbar {
    background-color: #333;
}
[data-theme='dark'] .ql-snow .ql-stroke{
    stroke: #fff;
}
[data-theme='dark'] .ql-toolbar button  {
    color: white !important; 
}
.ql-container {
    border-color: #E56B20; 
}
.ql-toolbar {
    border-color: #E56B20; 
}

